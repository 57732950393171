import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import { graphql } from "gatsby";

import {
  ComputerDesktopIcon,
  FaceSmileIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";

const seoData = {
  title: "ONAYLARIM",
  description: "Soru ve cevaplar.",
  slug: "",
};

const features = [
  {
    name: "JAVA Bağımsız",
    description:
      "Kullanıcılarınız e-imza atmak için bilgisayarlarına Java kurmak zorunda kalmasın. ",
    href: "#",
    icon: FaceSmileIcon,
  },
  {
    name: "Windows ve MACOS",
    description:
      "Her iki işletim sisteminde de sorunsuz bir biçimde e-imza atabilir.",
    href: "#",
    icon: ComputerDesktopIcon,
  },
  {
    name: "Gelişmiş e-İmza",
    description:
      "CAdES ve PAdES standartlarının detaylarını öğrenmeden, sadece API'leri çağırarak uzun dönem doğrulamalı yasal geçerli e-imza atabilirsiniz.",
    href: "#",
    icon: LockClosedIcon,
  },
];

const steps = [
  {
    number: 1,
    name: "UI Bileşenlerini Ekleyin",
    description:
      "Sağladığımız örnek Javascript kodlarını ve UI bileşenlerini mevcut web uygulamanıza ekleyin.",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-delivery-light.svg",
  },
  {
    number: 2,
    name: "Kendi ortamınıza 3 API ekleyin",
    description:
      "Sağladığımız örnek webAPI metodlarını servis katmanınıza ekleyin.",
    imageSrc: "https://tailwindui.com/img/ecommerce/icons/icon-chat-light.svg",
  },
  {
    number: 3,
    name: "Bizden API Anahtarı Alın",
    description:
      "ONAYLARIM PrimeAPI'yi kullanmak için bizden API anahtarı temin edin.",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-fast-checkout-light.svg",
  },
  {
    number: 4,
    name: "Belgelerinize e-İmza Atılsın",
    description:
      "Sisteminizi kullanan kullanıcılar Windows ve Mac bilgisayarlarından Java bağımlılığı olmadan gelişmiş imza atabilirler.",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-gift-card-light.svg",
  },
];

const featuresNew = [
  {
    name: "CAdES e-İmza",
    description:
      "CAdES standartının detaylarını öğrenmek zorunda kalmadan zaman damgalı gelişmiş e-imza atın.",
  },
  {
    name: "PAdES e-İmza",
    description: "PDF dosyalarına uzun dönem doğrulamalı, görsel imza atın.",
  },
  {
    name: "Mobil İmza",
    description: "Tüm operatörlerle mobil imza atabilirsiniz.",
  },
  {
    name: "PDF Dönüştürme",
    description: "Office ve resim dosyalarını PDF'e dönüştürebilirsiniz.",
  },
  {
    name: "PDF Üzerinde Değişiklik Yapma",
    description:
      "PDF üzerine e-imzalıdır ve doğrulama kodu bilgisi gibi alanları kolaylıkla ekleyebilirsiniz.",
  },
];

const faqs = [
  {
    question: "PrimeAPI Nedir?",
    answer:
      "PrimeAPI, yazılım geliştiricilerin kendi uygulamalarına e-imza atma özelliği eklemesini sağlayan bir platformdur.",
  },
  {
    question: "PrimeAPI bir SDK mıdır?",
    answer:
      "Hayır. SDK'larda olduğu gibi size DLL veya JAR dosyaları ile entegrasyon yapılmaz. PrimeAPI, ONAYLARIM altyapsını API'ler kullanarak e-imza atmanızı sağlar.",
  },
  {
    question: "PrimeAPI tek başına kullanılabilir mi?",
    answer:
      "Hem evet, hem hayır. PrimeAPI, ONAYLARIM ürününü kullanarak e-imza atılmasını sağlar. Ancak e-imza atacak kişilerin ONAYLARIM üyesi olmasına gerek yoktur.",
  },
];

const primeApi = () => {
  return (
    <Layout pageTitle="Prime API">
      <Seo
        title={seoData.title}
        description={seoData.description || seoData.excerpt || "nothin’"}
        pathname={seoData.slug}
        article
      />
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Uygulamanıza e-imza atma özelliği ekleyin
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Karmaşık ve kullanımı zor SDK'lar ile uğraşmadan, sadece API
              kullanarak mevcut uygulamalarınızda yasal geçerli e-imza ve mobil
              imza atılmasını sağlayabilirsiniz.
            </p>
          </div>
          <div className="mx-auto max-w-2xl mt-16 sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-sky-600">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                    {/* <p className="mt-6">
                      <a
                        href={feature.href}
                        className="text-sm font-semibold leading-6 text-sky-600"
                      >
                        Learn more <span aria-hidden="true">→</span>
                      </a>
                    </p> */}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-7xl lg:mx-0">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-center ">
              4 Adımda entegrasyonu tamamlayın
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600 text-center">
              Hangi geliştirme ortamını kullandığınız farketmez. Javascript ile
              en kısa sürede e-imza ve mobil imza atabilir hale gelin.
            </p>
          </div>
          <div className="mx-auto max-w-2xl mt-16 sm:mt-20 lg:mt-24 lg:max-w-none">
            <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 mt-16 sm:mt-20 lg:mt-24">
              {steps.map((step) => (
                <div key={step.name}>
                  {/* <img
                    src={step.imageSrc}
                    alt=""
                    className="h-24 w-auto"
                  /> */}
                  <div className="bg-sky-600 rounded-full flex w-12 h-12 items-center justify-items-center">
                    <div className="text-3xl  mx-auto text-white">
                      {step.number}
                    </div>
                  </div>
                  <h3 className="mt-6 text-sm font-medium text-gray-900">
                    {step.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {step.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-sky-600">
                Tüm ihtiyaçlarınız için
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Tek bir platform
              </p>
              <p className="mt-6 text-base leading-7 text-gray-600">
                e-İmza dışında doküman odaklı diğer servislerimizi de kullanarak
                geliştirme sürenizi kısaltabilirsiniz.
              </p>
            </div>
            <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
              {featuresNew.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <CheckIcon
                      className="absolute left-0 top-1 h-5 w-5 text-sky-500"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>
                  <dd className="mt-2">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      {/* CTA */}
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Üretkenliğinizi artırın.
            <br />
            primeAPI'yi bugün kullanmaya başlayın.
          </h2>
          <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
            <a
              href="mailto:info@seneka.com.tr"
              className="rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
            >
              Bize ulaşın
            </a>
            {/* <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </a> */}
          </div>
        </div>
      </div>
      {/* FAQ */}
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="lg:col-span-5">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                Sık sorulan sorular
              </h2>
              <p className="mt-4 text-base leading-7 text-gray-600">
                Aradığınız cevabı bulamadıysanız bize canlı chat veya{" "}
                <a
                  href="mailto:info@seneka.com.tr"
                  className="font-semibold text-sky-600 hover:text-sky-500"
                >
                  e-posta
                </a>{" "}
                ile ulaşabilirsiniz.
              </p>
            </div>
            <div className="mt-10 lg:col-span-7 lg:mt-0">
              <dl className="space-y-10">
                {faqs.map((faq) => (
                  <div key={faq.question}>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      {faq.question}
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      {faq.answer}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default primeApi;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
